import React, { useRef, forwardRef, useImperativeHandle, useState } from "react"
import { CONTACT_TYPE_HUMAN } from "../../Contacts/contactHelper"
import {
  PRIVACY_PUBLIC,
  DEFAULT_CONTACT_ADDRESS_OPTION,
  DEFAULT_CONTACT_PHONE_OPTION,
} from "../../../services/globalStaticData"
import AxiosInstance from "../../../services/axiosInstance"
import Translate from "../../translationHelper"

const ContactForm = forwardRef((props, ref) => {
  const formRef = useRef(null)
  const [uploadedFile, setUploadedFile] = useState(),
    [address, setAddress] = useState(""),
    [selectedPhoneType, setSelectedPhoneType] = useState(DEFAULT_CONTACT_PHONE_OPTION.type),
    [selectedAddressType, setSelectedAddressType] = useState(DEFAULT_CONTACT_ADDRESS_OPTION.type)

  const saveContact = e => {
    const formData = new FormData(formRef.current)

    if (formData.get("contactName") === "") {
      alert(Translate("Please enter Contact Name."))
      return false
    }

    let contactData = {
      name: formData.get("contactName"),
      email: formData.get("contactEmail"),
      profilePic: uploadedFile,
      companyName: formData.get("companyName"),
      jobTitle: formData.get("jobTitle"),
      contactType: CONTACT_TYPE_HUMAN,
      phone: {
        type: selectedPhoneType,
        phone: formData.get("contactPhone"),
        permission: PRIVACY_PUBLIC,
      },
      address: {
        type: selectedAddressType,
        address: address,
        permission: PRIVACY_PUBLIC,
      },
      metaData: [],
      customFields: [],
    }

    return new Promise(async () => {
      try {
        const response = await AxiosInstance.post(`/contact/add`, contactData)

        if (response.status === 201) {
          await props.callBack({ ...contactData, id: response.data.contact })
        }
      } catch (error) {
        console.error(error)
        alert(Translate("There is some error. Please try after sometime."))
      }
    })
  }

  useImperativeHandle(ref, () => ({
    saveContact,
  }))

  return (
    <form ref={formRef}>
      <div className="col-lg-12 mt-2 addNewUserField">
        <div className="row">
          <div className="col-lg-9">
            <div className="mb-3">
              <div className="row">
                <div className="col-md-6 mb-3 mb-md-0">
                  <div className="input-group">
                    <button
                      className="btn addContactUserType isHuman"
                      data-bs-toggle="tooltip"
                      title=""
                      data-bs-original-title="{Translate('Human')}"
                      aria-label="{Translate('Human')}"
                    >
                      <i className="bi bi-person-fill"></i>
                    </button>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="{Translate('Contact Name')}"
                      name="contactName"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <div className="dropdown addNewPhoneBox">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-phone-fill"></i> <span>{Translate("Mobile")}</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-telephone-fill"></i> {Translate("Main")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-phone-fill"></i> {Translate("Mobile")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-telephone-fill"></i> {Translate("Personal")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-telephone-fill"></i> {Translate("Business")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-phone-fill"></i> {Translate("iPhone")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-printer"></i> {Translate("Fax")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <input
                      type="text"
                      className="form-control contact_phone_us"
                      placeholder="{Translate('Phone')}"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 addContactUserTypeContactRow d-none">
              <div className="input-group">
                <div className="input-group-text">
                  <img src="images/no-image.jpg" />
                  <input
                    type="file"
                    accept="image/*"
                    className="contactPhotoFile"
                    style={{ visibility: "hidden", position: "absolute", left: "-10000px" }}
                  />
                  <a
                    href="#"
                    className="btnAddCompanyPhoto"
                    data-bs-toggle="tooltip"
                    title=""
                    data-bs-original-title="{Translate('Contact Photo')}"
                    aria-label="{Translate('Contact Photo')}"
                  >
                    <i className="bi bi-pencil-fill"></i>
                  </a>
                </div>
                <input
                  type="text"
                  className="form-control CD_input_info"
                  placeholder="{Translate('Contact Name')}"
                />
              </div>
            </div>
            <div className="mb-3 addContactUserTypeCompanyRow">
              <div className="row">
                <div className="col-md-6 mb-3 mb-md-0">
                  <div className="input-group">
                    <div className="input-group-text">
                      <img src="images/no-image.jpg" />
                      <input
                        type="file"
                        accept="image/*"
                        className="companyPhotoFile"
                        style={{
                          visibility: "hidden",
                          position: "absolute",
                          left: "-10000px",
                        }}
                      />
                      <a
                        href="#"
                        className="btnAddCompanyPhoto"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="{Translate('Company Photo')}"
                        aria-label="{Translate('Company Photo')}"
                      >
                        <i className="bi bi-pencil-fill"></i>
                      </a>
                    </div>
                    <input
                      type="text"
                      className="form-control CD_input_info contact_company"
                      placeholder="{Translate('Company')}"
                      name="companyName"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <div className="dropdown addNewPhoneBox">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-briefcase-fill"></i> <span>{Translate("Home")}</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-house-door-fill"></i> {Translate("Mailing")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-house-door-fill"></i> {Translate("Billing")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-house-door-fill"></i> {Translate("Work")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-house-door-fill"></i> {Translate("Other")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="{Translate('Job Title')}"
                      name="jobTitle"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <div className="row">
                <div className="col-md-6 mb-3 mb-md-0">
                  <div className="input-group">
                    <div className="dropdown addNewPhoneBox">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-envelope-fill"></i>
                        <span>{Translate("Home")}</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-envelope-fill"></i> {Translate("Primary")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-envelope-fill"></i> {Translate("Personal")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-envelope-fill"></i> {Translate("Work")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-envelope-fill"></i> {Translate("Other")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <input
                      type="email"
                      className="form-control CD_input_info contact_email"
                      placeholder="{Translate('Email')}"
                      name="contactEmail"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-group">
                    <div className="dropdown addNewPhoneBox">
                      <button
                        className="btn btn-default dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="bi bi-house-door-fill"></i> <span>{Translate("Home")}</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-house-door-fill"></i> {Translate("Mailing")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-house-door-fill"></i> {Translate("Billing")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-house-door-fill"></i> {Translate("Work")}
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#">
                            <i className="bi bi-house-door-fill"></i> {Translate("Other")}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="{Translate('Address or city')}"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="addNewContactAvatar d-inline-block">
              <img
                src={`${process.env.GATSBY_WRANGLER_URL}/StaticImages/avatar.gif`}
                alt="{Translate('Contact avatar')}"
                className="contact_pic img-fluid"
              />
              <a href="#" className="btnEditAvatar">
                <span
                  className="d-block"
                  data-bs-toggle="tooltip"
                  title=""
                  data-bs-original-title="{Translate('Contact avatar')}"
                  aria-label="{Translate('Contact avatar')}"
                >
                  <i className="bi bi-pencil-fill"></i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
})

export default ContactForm
